<template>
    <div>
        <div class="t-page" v-show="isSuccess == false">
            <div class="banner">
                <img src="https://resources.holowits.com.sg/HC/Shanghai-internal-eco-banner.jpg" alt="">
                <p style="color: #000000; font-size: 11px;">报名人数达到上限后将停止接受报名，请尽早注册。每次注册只能为一人报名，感谢您的配合。</p>
            </div>
            <div class="title first">
                基本信息
            </div>
            <div class="t-input" :class="{'error': rules.name}">
                <input class="el-input__inner" v-model="form.name"/>
                <div :class="['t-label', form.name != ''? 'focus': null]">姓名</div>
            </div>
            <div class="t-input" :class="{'error': rules.companyName}">
                <input class="el-input__inner" v-model="form.companyName"/>
                <div :class="['t-label', form.companyName != ''? 'focus': null]">公司</div>
            </div>
            <div class="t-input" :class="{'error': rules.job}">
                <input class="el-input__inner" v-model="form.job"/>
                <div :class="['t-label', form.job != ''? 'focus': null]">职位</div>
            </div>
            <div class="t-input" :class="{'error': rules.telephone}">
                <input class="el-input__inner" v-model="form.telephone"/>
                <div :class="['t-label', form.telephone != ''? 'focus': null]">手机号码</div>
            </div>
            <div class="title first">
                选择您将要参与的商务活动
            </div>
            <div class="t-input" :class="{'error': rules.newProductLaunchTime}">
                <el-select v-model="form.newProductLaunchTime" filterable placeholder="秋季新品发布会" style="width: 100%;" @focus="activity2 = true" @blur="activity2 = false">
                    <el-option label="不参与" value="不参与"></el-option>
                    <el-option label="9月20日：14:00-16:20" value="9月20日：14:00-16:20"></el-option>
                </el-select>
                <div :class="['t-label', form.newProductLaunchTime != ''? 'focus': null, activity2 ? 'focus2': null]">HOLOWITS 2024 秋季新品发布会</div>
            </div>
            <div class="t-input" :class="{'error': rules.ecologicalRoundtable}">
                <el-select v-model="form.ecologicalRoundtable" filterable placeholder="" style="width: 100%;" @focus="activity5 = true" @blur="activity5 = false">
                    <el-option label="不参与" value="不参与"></el-option>
                    <el-option label="9月20日 17:00-18:30" value="9月20日 17:00-18:30"></el-option>
                </el-select>
                <div :class="['t-label', form.ecologicalRoundtable != ''? 'focus': null, activity5 ? 'focus2': null]">生态圆桌</div>
            </div>
            <div class="t-input" :class="{'error': rules.eveningParty}">
                <el-select v-model="form.eveningParty" filterable placeholder="" style="width: 100%;" @focus="activity1 = true" @blur="activity1 = false">
                    <el-option label="不参与" value="不参与"></el-option>
                    <el-option label="9月20日 17:00-18:30" value="9月20日 17:00-18:30"></el-option>
                </el-select>
                <div :class="['t-label', form.eveningParty != ''? 'focus': null, activity1 ? 'focus2': null]">晚宴</div>
            </div>
            
            <div class="t-input" style="display: flex; justify-content: center;">
                <el-button round style="background-color: #c7000b; color: #ffffff; width: 160px;" :loading="isLoading" @click="add">提交</el-button>
            </div>
        </div>
        <div v-show="isSuccess === true" style="text-align: center;">
            <img src="https://resources.holowits.com.sg/HC/shanghai-eco0821.jpg" alt="">
        </div>
    </div>
</template>
  
<script>
  import countrys from '@/utils/country.json';
  import {save} from '@/api/otherFormApi';
  export default {
    name: 'ShanghaiInternalIndex',
  data() {
      return {
          isSuccess: false,
          language: 'en',
          activity1: false,
          activity2: false,
          activity3: false,
          activity5: false,
          form: {
            name: "",
            companyName: "",
            job: "",
            telephone: "",
            newProductLaunchTime: "",
            ecologicalRoundtable: "",
            eveningParty: "",
          },
          rules: {
            name: false,
            companyName: false,
            job: false,
            telephone: false,
            newProductLaunchTime: false,
            ecologicalRoundtable: false,
            eveningParty: false,
          },
          countryArr: [],
          isLoading: false,
          hasError: false
      }
  },
  created() {
      this.countryArr = countrys.country.map(item => {
          return {label: item, value: item}
      })
  },
  watch: {
    form: {
        handler(n, v) {
            let checkList = Object.keys(n)
            for (let key of checkList) {
                this.check(key, n[key])
            }
        },
        deep: true
    }
  },
  methods: {
    check(key, value) {
        if (key in this.rules) {
            return this.rules[key] = value ? false : true;
        }
    },
     async add() {
        this.form['source'] = 'shanghai-eco'
        let checkList = Object.keys(this.rules)
        let hasError = false
        for (let key of checkList) {
            if (this.check(key, this.form[key])) {
                hasError = true
            }
        }
        if (hasError) {
            return false;
        }

          this.isLoading = true
          try {
            const {data: {data, isSuccess}} = await save({
                content: JSON.stringify(this.form),
                pageSource: 'shanghai-eco'
            })
            if (isSuccess) {
                this.isSuccess = true
                this.isLoading = false
            } else {
                this.isLoading = false
            }
          } catch(e) {
            this.isLoading = false
          }
          
    }
  }
  }
</script>
  
<style lang="scss" scoped>
  .t-page {
    padding-bottom: 30px;
  }
  .banner {
      width: 100%;
      img {
          width: 100%;
      }
  }
  .error-info {
      color: red;
      font-size: 12px;
  }
  .company-icon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  
      .left {
          width: 163px;
          height: 80px;
      }
      .line {
          height: 40px;
          width: 2px;
          background-color: #000000;
      }
      .right {
          width: 105px;
          height: 105px;
      }
  }
  .first {
      margin-top: 20px;
  }
  
  .title {
      font-size: 18px;;
      font-weight: 600;
      margin-bottom: 20px;
  }
  .require {
    &::after {
        content: '*';
        padding-left: 2px;
        color: #c7000b;
    }
  }
  .t-input {
      margin-bottom: 20px;
      position: relative;
  
      .t-label {
          position: absolute;
          font-size: 14px;
          top: 10px;
          left: 16px;
          background-color: #ffffff;
          color: #C0C4CC;
          transition: all 0.4s;
  
          &::after {
              content: '*';
              padding-left: 2px;
              color: #c7000b;
          }
      }
      .optional {
          &::after {
              content: '';
          }
      }
      .focus {
          padding: 0 5px;
          top: -12px;
          font-size: 12px;
      }
      .focus2 {
          padding: 0 5px;
          top: -12px;
          left: 12px;
          font-size: 12px;
      }
      .el-input__inner:focus+.t-label {
          padding: 0 5px;
          top: -12px;
          font-size: 12px;
      }
  }
  .error {
    ::v-deep input {
        border-color: rgb(199, 0, 11) !important;
    }
    ::v-deep textarea {
        border-color: rgb(199, 0, 11) !important;
    }
  }
  @media only screen and (min-width: 992px) {
      .t-page {
          max-width: 838px;
          margin: 20px auto;
      }
  }
  @media only screen and (max-width: 991px) {
      .t-page {
          width: 100%;
          margin: 20px auto;
          padding: 15px;
      }
  }
</style>